import React, { useEffect } from 'react';
import { Box, Link } from '@mui/material';

function QualityManagementTemplates() {
    useEffect(() => {
        // Disable cache for this page
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', () => {
            window.history.pushState(null, null, window.location.href);
        });
    }, []);
    
    const links1 = [
        {
            label: 'محضر نموذج رقم (9) إدارة الجودة والمخاطر والارشفة والمحفوظات (وورد)',
            url: '',
        },
        { label: 'محضر استلام اصل مستندات نموذج رقم (ظ/1) (وورد)', url: '' },
        {
            label: 'محضر تقرير شهري عن إدارة وجودتها نموذج رقم (ظ/2) (وورد)',
            url: '',
        },
        {
            label: 'محضر تقرير شهري عن إدارة والمخاطر فيها نموذج رقم (ظ/3) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر المستندات الورقية التي تم تسليمها خلال ثلاثة اشهر نموذج رقم (ظ/4) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر المستندات الورقية في الإدارة وتحديثها نموذج رقم (ظ/5) (وورد)',
            url: '',
        },
        {
            label: 'فهرس حصر أصول المستندات الخاصة بالعملاء نموذج رقم (ظ/6) (وورد)',
            url: '',
        },
        { label: 'فهرس ملفات أصول المستندات نموذج رقم (ظ/7) (وورد)', url: '' },
        { label: 'محضر اثبات حالة نموذج رقم (ظ/8) (وورد)', url: '' },
        { label: 'خطاب للإدارة في المنشأة نموذج رقم (ظ/9) (وورد)', url: '' },
    ];

    return (
        <div className="px-3 pb-5">
            <h1 className="text-center my-4">
                {links1.length} إدارة الجودة والمخاطر والارشفة والمحفوظات
            </h1>
            <Box
                dir="ltr"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                mt={4}
            >
                {links1.map((link, index) => (
                    <Box
                        dir="ltr"
                        key={index}
                        component="div"
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            width: '100%',
                            maxWidth: '450px',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Link
                            href={link.url}
                            underline="none"
                            color="primary"
                            target="_blank"
                        >
                            <p className="mb-0" align="center">
                                {link.label}
                            </p>
                        </Link>
                    </Box>
                ))}
            </Box>
        </div>
    );
}

export default QualityManagementTemplates;
