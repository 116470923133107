import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import SystemLinks from './pages/SystemLinks/SystemLinks';
import LegalTemplates from './pages/Templates/LegalTemplates';
import QualityManagementTemplates from './pages/Templates/QualityManagementTemplates';
import NoMatch from './pages/NoMatch';
import ManagerialTemplates from './pages/Templates/ManagerialTemplates';
import FinancialTemplates from './pages/Templates/FinancialTemplates';
import GovLinks from './pages/Templates/GovLinks';
import Platforms from './pages/Templates/Platforms';
import Login from './pages/Login/Login';
import withPermissionGuard from './guards/withPermissionGuard';
import withAuthenticatedGuard from './guards/withAuthenticatedGuard';
// import Career from './modules/careers/components/Career';
// import Eid from './pages/Eid/Eid';

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />}></Route>
                    <Route path="contact" element={<Contact />}></Route>
                    {/* <Route path="careers" element={<Career />}></Route> */}
                    <Route
                        path="legal-templates"
                        element={withPermissionGuard(LegalTemplates, [
                            'Legal Models',
                        ])()}
                    ></Route>
                    <Route
                        path="managerial-templates"
                        element={withPermissionGuard(ManagerialTemplates, [
                            'Administrative Models',
                        ])()}
                    ></Route>
                    <Route path="gov-links" element={withAuthenticatedGuard(GovLinks)()}></Route>
                    <Route
                        path="platforms"
                        element={withAuthenticatedGuard(Platforms)()}
                    ></Route>
                    <Route
                        path="quality-management"
                        element={withPermissionGuard(
                            QualityManagementTemplates,
                            ['Quality Management']
                        )()}
                    ></Route>
                    <Route
                        path="finance"
                        element={withPermissionGuard(FinancialTemplates, [
                            'All Financial Forms',
                        ])()}
                    ></Route>
                    <Route path="login" element={<Login />}></Route>
                    <Route
                        path="links"
                        element={withAuthenticatedGuard(SystemLinks)()}
                    ></Route>
                    {/* <Route path="eid-card" element={<Eid />}></Route> */}
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default AppRoutes;
